import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "@components/Theater/TheaterVideo";
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import { Link } from "gatsby"


const ExparelAsird = ({ data, pageContext, location }) => {
  let language = pageContext.language

  const post = data.allExparelAsirdJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content">
          <div className="exparel-banner">
          <ImageMeta
            cloudName="nuvolum"
            publicId={post.logoImage}
            responsive
          />
          </div>
          <div className="columns top-section white-back" style={{paddingTop: 0}}>
            <div className="column is-4"></div>
            <div className="column">
              <MarkdownViewer markdown={post.body} />
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query exparlAsirdPages ($title: String!)  {
  allExparelAsirdJson(filter: {title: {eq: $title}}) {
    nodes {
      title
      metaTitle
      metaDescription
      body
      logoImage
      backgroundImage
    }
  }
}`

export default ExparelAsird